<template>
  <div class="w-full max-h-screen bg-background h-full">
    <page-header
      backTo="tools-ivy-clients"
      title="iV Client Detail"
      :enableLogicalBack="true"
    >
      <!-- <button
        @click="changeViewMode"
        type="button"
        class="flex items-center bg-teal-200 border hover:bg-teal-300 border-teal-400 hover:border-teal-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <rect width="22" height="14" x="1" y="5" rx="7" ry="7" />
          <circle cx="16" cy="12" r="3" />
        </svg>
        <span>Sales view</span>
      </button> -->
      <!-- <button
        @click="showingCreateTicketPanel = true"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path
            d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
          />
        </svg>
        <span>Create Ticket</span>
      </button> -->
    </page-header>
    <main class="p-6 pb-20 bg-background flex-1 overflow-y-scroll h-full">
      <div v-if="client">
        <div class="flex w-full space-x-4">
          <div class="w-full bg-white p-5 rounded shadow-md flex flex-1">
            <div class="w-2/3">
              <h2 class="text-3xl mb-4">
                {{ client.client_name }}
                <button
                  @click="openEditPanel"
                  v-if="
                    client.metadata && client.metadata.activated_product_codes
                  "
                  class="ml-4 text-secondary bg-white p-1 rounded border border-transparent hover:border-secondary active:border-secondary active:shadow-inner active:bg-gray-200 transition duration-100"
                >
                  <svg
                    class="stroke-current h-6 w-6"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                    />
                  </svg>
                </button>
              </h2>

              <div v-if="client.org_name" class="flex items-center py-1">
                <div>
                  <svg
                    class="text-orange-700 stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                    <circle cx="12" cy="7" r="4" />
                  </svg>
                </div>
                <!-- <p class="bg-orange-200 text-orange-700 rounded-lg py-1 px-2">
                    Agent Name
                  </p> -->
                <p class="rounded-lg px-2 py-1">
                  Organisation: {{ client.org_name }}
                </p>
              </div>

              <div v-if="client.agent_name" class="flex items-center py-1">
                <div>
                  <svg
                    class="text-orange-700 stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                    <circle cx="12" cy="7" r="4" />
                  </svg>
                </div>
                <!-- <p class="bg-orange-200 text-orange-700 rounded-lg py-1 px-2">
                    Agent Name
                  </p> -->
                <p class="rounded-lg px-2 py-1">
                  Agent: {{ client.agent_name }}
                </p>
              </div>

              <div class="flex items-center py-1">
                <div>
                  <svg
                    class="text-orange-700 stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
                    <circle cx="12" cy="10" r="3" />
                  </svg>
                </div>
                <p class="rounded-lg px-2 py-1">
                  {{ formatDefaultAddress() }}
                </p>
              </div>
              <div class="flex items-center py-1">
                <div>
                  <svg
                    class="text-orange-700 stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <rect
                      x="1"
                      y="4"
                      width="22"
                      height="16"
                      rx="2"
                      ry="2"
                    ></rect>
                    <line x1="1" y1="10" x2="23" y2="10"></line>
                  </svg>
                </div>
                <p class="rounded-lg px-2 py-1">
                  Payment Status:
                  {{ client.metadata.payment_status || "Pending" }}
                </p>
              </div>

              <div
                v-if="client.metadata.created_by_boston_user"
                class="flex items-center py-1"
              >
                <div>
                  <svg
                    class="text-orange-700 stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                    <circle cx="12" cy="7" r="4" />
                  </svg>
                </div>
                <!-- <p class="bg-orange-200 text-orange-700 rounded-lg py-1 px-2">
                    Agent Name
                  </p> -->
                <p class="rounded-lg px-2 py-1">
                  Created by: {{ client.metadata.created_by_boston_user }}
                </p>
              </div>


              <div v-if="client.metadata.notes" class="flex items-center py-1">
                <div>
                  <svg
                   class="text-orange-700 stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                    />
                  </svg>
                </div>
                <p class="rounded-lg px-2 py-1">
                  Notes: {{ client.metadata.notes }}
                </p>
              </div>

              <!-- <div v-if="contact.people && contact.people.length > 0">
                  
                  <div
                    v-if="contact.people[0].email"
                    class="flex items-center py-1"
                  >
                    <svg
                      class="text-orange-700 stroke-current h-5 w-5 mr-2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                      />
                      <path d="M22 6l-10 7L2 6" />
                    </svg>
                    <a
                      class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                      :href="'mailto:' + contact.people[0].email"
                      >{{ contact.people[0].email }}</a
                    >
                  </div>
                  <div
                    v-if="
                      contact.people[0].mobile || contact.people[0].telephone
                    "
                    class="flex items-center py-1"
                  >
                    <svg
                      class="text-orange-700 stroke-current h-5 w-5 mr-2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"
                      />
                    </svg>
                    <a
                      v-if="contact.people[0].mobile"
                      class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                      :href="'tel:' + contact.people[0].mobile"
                      >{{ contact.people[0].mobile }}</a
                    >
                    <a
                      v-if="contact.people[0].telephone"
                      class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                      :href="'tel:' + contact.people[0].telephone"
                      >{{ contact.people[0].telephone }}</a
                    >
                  </div>

                  <div v-if="contact.website" class="flex items-center py-1">
                    <svg
                      class="text-orange-700 stroke-current h-5 w-5 mr-2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <path
                        d="M2 12h20M12 2a15.3 15.3 0 014 10 15.3 15.3 0 01-4 10 15.3 15.3 0 01-4-10 15.3 15.3 0 014-10z"
                      />
                    </svg>
                    <a
                      class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                      :href="'//' + contact.website"
                      target="_blank"
                    >
                      {{ contact.website }}
                    </a>
                  </div>
                </div> -->
            </div>

            <!-- Is Customer/Agent/Supplier flags -->
            <div class="w-1/3 flex justify-end items-top mt-2">
              <div class="flex flex-col align-top items-center">
                <div class="flex -mx-1">
                  <div
                    class="flex items-center mx-1 border-2 border-red-600 text-red-700 font-bold uppercase text-sm px-2 py-1 leading-none rounded-lg select-none"
                    v-if="!client.is_activated"
                  >
                    <span>Not Activated</span>
                  </div>
                  <div
                    class="flex items-center mx-1 border-2 border-green-600 text-green-700 font-bold uppercase text-sm px-2 py-1 leading-none rounded-lg select-none"
                    v-if="client.is_activated"
                  >
                    <span>Activated</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Is Customer/Agent/Supplier flags -->
          </div>
        </div>

        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <div class="w-full mb-6">
            <div class="flex -mx-2 mr-2">
              <router-link
                to="#information"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="
                  $route.hash == '' || $route.hash == '#client-properties'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Client Properties</span>
              </router-link>

              <!-- <router-link
                to="#service-tickets"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="
                  $route.hash == '#service-tickets'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Service Tickets</span>
              </router-link> -->

              <!-- <button
                @click="currentTab = 'documents'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'documents',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'documents',
                }"
              >
                <span>Documents</span>
              </button> -->
              <!-- <button
                @click="currentTab = 'invoices'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'invoices',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'invoices',
                }"
              >
                <span>Invoices</span>
              </button> -->
            </div>
          </div>
          <div v-if="$route.hash == '' || $route.hash == '#client-properties'">
            <ClientProperties :client="client" />
          </div>
          <!-- <div v-if="$route.hash == '#people'">
            <People :people="contact.people" />
          </div> -->

          <div v-if="error">
            <div
              class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
            >
              <p>
                Couldn't load client
                {{ error.statusText }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="bg-white rounded shadow-md flex items-center justify-center py-20"
      >
        <spinner :color="'text-black'" :size="10" />
      </div>
    </main>
    <portal to="overlay-outlet">
      <panel
        :showing="editDetailsPanelOpen"
        @close="editDetailsPanelOpen = false"
        title="Edit Client Details"
      >
        <EditClientDetails :client="client" @complete="closeEditDetailsPanel" />
      </panel>
    </portal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Notification from "@/components/Notification.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import Alert from "@/components/Alert.vue";
import Table from "@/components/Table.vue";
import EditClientDetails from "@/components/Tools/IvyClients/EditClientDetails.vue";
import ClientProperties from "@/components/Tools/IvyClients/ClientProperties.vue";

export default {
  name: "IvyClientDetail",
  components: {
    PageHeader,
    Notification,
    Spinner,
    Panel,
    Alert,
    Table,
    EditClientDetails,
    ClientProperties,
  },
  props: {
    clientId: String,
  },
  data() {
    return {
      viewState: "Idle",
      error: null,
      client: null,
      editDetailsPanelOpen: false,
    };
  },
  async mounted() {
    await this.loadClientDetails();
  },
  methods: {
    async loadClientDetails() {
      this.viewState = "Loading";
      this.client = await this.iVClientService.getIvyActivationById(
        this.clientId
      );
      console.log("client", this.client);
    },
    formatDefaultAddress: function () {
      return [
        this.client.location_name,
        this.client.location_address.address1,
        this.client.location_address.address2,
        this.client.location_address.address3,
        this.client.location_address.city,
        this.client.location_address.zip,
      ]
        .filter((part) => !!part)
        .join(", ");
    },
    openEditPanel: function () {
      this.editDetailsPanelOpen = true;
    },
    closeEditDetailsPanel: async function () {
      this.editDetailsPanelOpen = false;
      await this.loadClientDetails();
    },
  },
};
</script>
